import React from 'react';
import './TopHero.css';

export default function TopHero() {
  return (
    <div className='container'>
    <div className='textContainer'>
        
    <h3>Read carefully,</h3>
    <h1 className='mainText'>ONTOCRIPT iT PRIVACY POLICY</h1>
    <h5 className='privacy-slogan'>Last Updated : December 25, 2023</h5>


    </div>
   

   

  
</div>
  )
}
